export const LOCALES = Object.freeze({
  ZH: 'zh-CN',
  EN: 'en-US',
  JA: 'ja',
})

export const ZhPhoneCode = {
  '1345': '开曼群岛',
  '0244': '安哥拉',
  '93': '阿富汗',
  '335': '阿尔巴尼亚',
  '213': '阿尔及利亚',
  '376': '安道尔共和国',
  '1254': '安圭拉岛',
  '1268': '安提瓜和巴布达',
  '54': '阿根廷',
  '374': '亚美尼亚',
  '247': '阿森松',
  '61': '澳大利亚',
  '43': '奥地利',
  '994': '阿塞拜疆',
  '1242': '巴哈马',
  '973': '巴林',
  '880': '孟加拉国',
  '1246': '巴巴多斯',
  '375': '白俄罗斯',
  '32': '比利时',
  '501': '伯利兹',
  '229': '贝宁',
  '1441': '百慕大群岛',
  '591': '玻利维亚',
  '267': '博茨瓦纳',
  '55': '巴西',
  '673': '文莱',
  '359': '保加利亚',
  '226': '布基纳法索',
  '95': '缅甸',
  '257': '布隆迪',
  '237': '喀麦隆',
  '1': '美国',
  '236': '中非共和国',
  '235': '乍得',
  '56': '智利',
  '86': '中国',
  '57': '哥伦比亚',
  '242': '刚果',
  '682': '库克群岛',
  '506': '哥斯达黎加',
  '53': '古巴',
  '357': '塞浦路斯',
  '420': '捷克',
  '45': '丹麦',
  '253': '吉布提',
  '1890': '多米尼加共和国',
  '593': '厄瓜多尔',
  '20': '埃及',
  '503': '萨尔瓦多',
  '372': '爱沙尼亚',
  '251': '埃塞俄比亚',
  '679': '斐济',
  '358': '芬兰',
  '33': '法国',
  '594': '法属圭亚那',
  '689': '法属玻利尼西亚',
  '241': '加蓬',
  '220': '冈比亚',
  '995': '格鲁吉亚',
  '49': '德国',
  '233': '乌兹别克斯坦',
  '350': '直布罗陀',
  '30': '希腊',
  '1809': '特立尼达和多巴哥',
  '1671': '关岛',
  '502': '危地马拉',
  '224': '几内亚',
  '592': '圭亚那',
  '509': '海地',
  '504': '洪都拉斯',
  '36': '匈牙利',
  '354': '冰岛',
  '91': '印度',
  '62': '印度尼西亚',
  '98': '伊朗',
  '964': '伊拉克',
  '353': '爱尔兰',
  '972': '以色列',
  '39': '意大利',
  '225': '科特迪瓦',
  '1876': '牙买加',
  '81': '日本',
  '962': '约旦',
  '855': '柬埔寨',
  '327': '哈萨克斯坦',
  '254': '肯尼亚',
  '82': '韩国',
  '965': '科威特',
  '331': '吉尔吉斯坦',
  '856': '老挝',
  '371': '拉脱维亚',
  '961': '黎巴嫩',
  '266': '莱索托',
  '231': '利比里亚',
  '218': '利比亚',
  '423': '列支敦士登',
  '370': '立陶宛',
  '352': '卢森堡',
  '261': '马达加斯加',
  '265': '马拉维',
  '60': '马来西亚',
  '960': '马尔代夫',
  '223': '马里',
  '356': '马耳他',
  '1670': '马里亚那群岛',
  '596': '马提尼克',
  '230': '毛里求斯',
  '52': '墨西哥',
  '373': '摩尔多瓦',
  '377': '摩纳哥',
  '976': '蒙古',
  '1664': '蒙特塞拉特岛',
  '212': '摩洛哥',
  '258': '莫桑比克',
  '264': '纳米比亚',
  '674': '瑙鲁',
  '977': '尼泊尔',
  '599': '荷属安的列斯',
  '31': '荷兰',
  '64': '新西兰',
  '505': '尼加拉瓜',
  '227': '尼日尔',
  '234': '尼日利亚',
  '850': '朝鲜',
  '47': '挪威',
  '968': '阿曼',
  '92': '巴基斯坦',
  '507': '巴拿马',
  '675': '巴布亚新几内亚',
  '595': '巴拉圭',
  '51': '秘鲁',
  '63': '菲律宾',
  '48': '波兰',
  '351': '葡萄牙',
  '1787': '波多黎各',
  '974': '卡塔尔',
  '262': '留尼旺',
  '40': '罗马尼亚',
  '7': '俄罗斯',
  '1758': '圣卢西亚',
  '1784': '圣文森特',
  '684': '东萨摩亚(美)',
  '685': '西萨摩亚',
  '378': '圣马力诺',
  '239': '圣多美和普林西比',
  '966': '沙特阿拉伯',
  '221': '塞内加尔',
  '248': '塞舌尔',
  '232': '塞拉利昂',
  '65': '新加坡',
  '421': '斯洛伐克',
  '386': '斯洛文尼亚',
  '677': '所罗门群岛',
  '252': '索马里',
  '27': '南非',
  '34': '西班牙',
  '94': '斯里兰卡',
  '249': '苏丹',
  '597': '苏里南',
  '268': '斯威士兰',
  '46': '瑞典',
  '41': '瑞士',
  '963': '叙利亚',
  '992': '塔吉克斯坦',
  '255': '坦桑尼亚',
  '66': '泰国',
  '228': '多哥',
  '676': '汤加',
  '216': '突尼斯',
  '90': '土耳其',
  '993': '土库曼斯坦',
  '256': '乌干达',
  '380': '乌克兰',
  '971': '阿拉伯联合酋长国',
  '44': '英国',
  '598': '乌拉圭',
  '58': '委内瑞拉',
  '84': '越南',
  '967': '也门',
  '381': '南斯拉夫',
  '263': '津巴布韦',
  '243': '扎伊尔',
  '260': '赞比亚',
}
export const EnPhoneCode = {
  '1345': 'Cayman Is',
  '0244': 'Angola',
  '93': 'Afghanistan',
  '335': 'Albania',
  '213': 'Algeria',
  '376': 'Andorra',
  '1254': 'Anguilla',
  '1268': 'Antigua and Barbuda',
  '54': 'Argentina',
  '374': 'Armenia',
  '247': 'Ascension',
  '61': 'Australia',
  '43': 'Austria',
  '994': 'Azerbaijan',
  '1242': 'Bahamas',
  '973': 'Bahrain',
  '880': 'Bangladesh',
  '1246': 'Barbados',
  '375': 'Belarus',
  '32': 'Belgium',
  '501': 'Belize',
  '229': 'Benin',
  '1441': 'Bermuda Is',
  '591': 'Bolivia',
  '267': 'Botswana',
  '55': 'Brazil',
  '673': 'Brunei',
  '359': 'Bulgaria',
  '226': 'Burkina Faso',
  '95': 'Burma',
  '257': 'Burundi',
  '237': 'Cameroon',
  '1': 'United States of America',
  '236': 'Central African Republic',
  '235': 'Chad',
  '56': 'Chile',
  '86': 'China',
  '57': 'Colombia',
  '242': 'Congo',
  '682': 'Cook Is',
  '506': 'Costa Rica',
  '53': 'Cuba',
  '357': 'Cyprus',
  '420': 'Czech Republic',
  '45': 'Denmark',
  '253': 'Djibouti',
  '1890': 'Dominica Rep',
  '593': 'Ecuador',
  '20': 'Egypt',
  '503': 'EI Salvador',
  '372': 'Estonia',
  '251': 'Ethiopia',
  '679': 'Fiji',
  '358': 'Finland',
  '33': 'France',
  '594': 'French Guiana',
  '689': 'French Polynesia',
  '241': 'Gabon',
  '220': 'Gambia',
  '995': 'Georgia',
  '49': 'Germany',
  '233': 'Uzbekistan',
  '350': 'Gibraltar',
  '30': 'Greece',
  '1809': 'Trinidad and Tobago',
  '1671': 'Guam',
  '502': 'Guatemala',
  '224': 'Guinea',
  '592': 'Guyana',
  '509': 'Haiti',
  '504': 'Honduras',
  '36': 'Hungary',
  '354': 'Iceland',
  '91': 'India',
  '62': 'Indonesia',
  '98': 'Iran',
  '964': 'Iraq',
  '353': 'Ireland',
  '972': 'Israel',
  '39': 'Italy',
  '225': 'Ivory Coast',
  '1876': 'Jamaica',
  '81': 'Japan',
  '962': 'Jordan',
  '855': 'Kampuchea (Cambodia )',
  '327': 'Kazakstan',
  '254': 'Kenya',
  '82': 'Korea',
  '965': 'Kuwait',
  '331': 'Kyrgyzstan',
  '856': 'Laos',
  '371': 'Latvia',
  '961': 'Lebanon',
  '266': 'Lesotho',
  '231': 'Liberia',
  '218': 'Libya',
  '423': 'Liechtenstein',
  '370': 'Lithuania',
  '352': 'Luxembourg',
  '261': 'Madagascar',
  '265': 'Malawi',
  '60': 'Malaysia',
  '960': 'Maldives',
  '223': 'Mali',
  '356': 'Malta',
  '1670': 'Mariana Is',
  '596': 'Martinique',
  '230': 'Mauritius',
  '52': 'Mexico',
  '373': 'Moldova',
  '377': 'Monaco',
  '976': 'Mongolia',
  '1664': 'Montserrat Is',
  '212': 'Morocco',
  '258': 'Mozambique',
  '264': 'Namibia',
  '674': 'Nauru',
  '977': 'Nepal',
  '599': 'Netheriands Antilles',
  '31': 'Netherlands',
  '64': 'New Zealand',
  '505': 'Nicaragua',
  '227': 'Niger',
  '234': 'Nigeria',
  '850': 'North Korea',
  '47': 'Norway',
  '968': 'Oman',
  '92': 'Pakistan',
  '507': 'Panama',
  '675': 'Papua New Cuinea',
  '595': 'Paraguay',
  '51': 'Peru',
  '63': 'Philippines',
  '48': 'Poland',
  '351': 'Portugal',
  '1787': 'Puerto Rico',
  '974': 'Qatar',
  '262': 'Reunion',
  '40': 'Romania',
  '7': 'Russia',
  '1758': 'St.Lucia',
  '1784': 'St.Vincent',
  '684': 'Samoa Eastern',
  '685': 'Samoa Western',
  '378': 'San Marino',
  '239': 'Sao Tome and Principe',
  '966': 'Saudi Arabia',
  '221': 'Senegal',
  '248': 'Seychelles',
  '232': 'Sierra Leone',
  '65': 'Singapore',
  '421': 'Slovakia',
  '386': 'Slovenia',
  '677': 'Solomon Is',
  '252': 'Somali',
  '27': 'South Africa',
  '34': 'Spain',
  '94': 'SriLanka',
  '249': 'Sudan',
  '597': 'Suriname',
  '268': 'Swaziland',
  '46': 'Sweden',
  '41': 'Switzerland',
  '963': 'Syria',
  '992': 'Tajikstan',
  '255': 'Tanzania',
  '66': 'Thailand',
  '228': 'Togo',
  '676': 'Tonga',
  '216': 'Tunisia',
  '90': 'Turkey',
  '993': 'Turkmenistan',
  '256': 'Uganda',
  '380': 'Ukraine',
  '971': 'United Arab Emirates',
  '44': 'United Kiongdom',
  '598': 'Uruguay',
  '58': 'Venezuela',
  '84': 'Vietnam',
  '967': 'Yemen',
  '381': 'Yugoslavia',
  '263': 'Zimbabwe',
  '243': 'Zaire',
  '260': 'Zambia',
}
