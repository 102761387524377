module.exports = {
  getCookie: key => {
    const arr = document.cookie.split('; ').find(row => row.startsWith(`${key}=`))
    return arr ? arr.split(`${key}=`)[1] : null
  },
  setCookie: (key, value, days) => {
    const maxAge = 24 * 60 * 60 * days
    document.cookie = `${key}=${value};max-age=${maxAge};expires=${new Date(Date.now() + maxAge * 1000)};`
  },
}
