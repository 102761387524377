import { LOCALES as localeConfig } from '../config'
import { navigate as gatsbyNavigate } from 'gatsby'
export const LOCALES = localeConfig

export class LocaleService {
  static currentLocale = process.env.GATSBY_LOCALE

  // TODO： 要把项目里的isEqualEn都替换成isForeign
  static isEqualEn = LocaleService.currentLocale === LOCALES.EN

  static isEqualJA = LocaleService.currentLocale === LOCALES.JA

  // 是否是国外版本
  static isForeign = process.env.GATSBY_LOCALE !== LOCALES.ZH

  static getLocalePath() {
    if (typeof window !== 'undefined') {
      const pathname = window.location.pathname
      const currentLocale = Object.values(LOCALES).find(locale => pathname.includes(`/${locale}/`))
      return currentLocale
    }
  }

  static getPathname() {
    if (typeof window !== 'undefined') {
      let pathname = window.location.pathname
      const currentLocale = Object.values(LOCALES).find(locale => pathname.includes(`/${locale}/`))
      if (currentLocale) {
        pathname = pathname.split(`/${currentLocale}/`)[1]
      }
      return pathname
    }
  }

  static changeLocale(locale) {
    const pathname = this.getPathname()

    if (locale === LOCALES.EN) {
      gatsbyNavigate(`/${pathname}`)
    } else {
      gatsbyNavigate(`/${locale}/${pathname}`)
    }
  }

  static getCurrentLocale() {
    return this.currentLocale
  }

  static setCurrentLocale(locale) {
    this.currentLocale = locale
  }
}
