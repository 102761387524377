// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-views-index-jsx": () => import("./../src/views/index.jsx" /* webpackChunkName: "component---src-views-index-jsx" */),
  "component---src-views-products-raysync-ferry-jsx": () => import("./../src/views/products/raysync-ferry.jsx" /* webpackChunkName: "component---src-views-products-raysync-ferry-jsx" */),
  "component---src-views-products-ftp-alternative-upgrade-jsx": () => import("./../src/views/products/ftp-alternative-upgrade.jsx" /* webpackChunkName: "component---src-views-products-ftp-alternative-upgrade-jsx" */),
  "component---src-views-404-jsx": () => import("./../src/views/404.jsx" /* webpackChunkName: "component---src-views-404-jsx" */),
  "component---src-views-examples-detail-jsx": () => import("./../src/views/examples/detail.jsx" /* webpackChunkName: "component---src-views-examples-detail-jsx" */),
  "component---src-views-examples-index-jsx": () => import("./../src/views/examples/index.jsx" /* webpackChunkName: "component---src-views-examples-index-jsx" */),
  "component---src-views-calculator-jsx": () => import("./../src/views/calculator.jsx" /* webpackChunkName: "component---src-views-calculator-jsx" */),
  "component---src-views-partners-index-jsx": () => import("./../src/views/partners/index.jsx" /* webpackChunkName: "component---src-views-partners-index-jsx" */),
  "component---src-views-about-us-jsx": () => import("./../src/views/about-us.jsx" /* webpackChunkName: "component---src-views-about-us-jsx" */),
  "component---src-views-en-pricing-pricing-jsx": () => import("./../src/views/en/pricing/pricing.jsx" /* webpackChunkName: "component---src-views-en-pricing-pricing-jsx" */),
  "component---src-views-apply-jsx": () => import("./../src/views/apply.jsx" /* webpackChunkName: "component---src-views-apply-jsx" */),
  "component---src-views-products-product-sdk-jsx": () => import("./../src/views/products/product-sdk.jsx" /* webpackChunkName: "component---src-views-products-product-sdk-jsx" */),
  "component---src-views-products-product-vpn-jsx": () => import("./../src/views/products/product-vpn.jsx" /* webpackChunkName: "component---src-views-products-product-vpn-jsx" */),
  "component---src-views-products-product-file-jsx": () => import("./../src/views/products/product-file.jsx" /* webpackChunkName: "component---src-views-products-product-file-jsx" */),
  "component---src-views-products-product-ftp-jsx": () => import("./../src/views/products/product-ftp.jsx" /* webpackChunkName: "component---src-views-products-product-ftp-jsx" */),
  "component---src-views-products-product-ssh-jsx": () => import("./../src/views/products/product-ssh.jsx" /* webpackChunkName: "component---src-views-products-product-ssh-jsx" */),
  "component---src-views-solutions-file-exchange-jsx": () => import("./../src/views/solutions/file-exchange.jsx" /* webpackChunkName: "component---src-views-solutions-file-exchange-jsx" */),
  "component---src-views-term-of-service-jsx": () => import("./../src/views/term-of-service.jsx" /* webpackChunkName: "component---src-views-term-of-service-jsx" */),
  "component---src-views-license-purchase-traffic-jsx": () => import("./../src/views/license/purchase-traffic.jsx" /* webpackChunkName: "component---src-views-license-purchase-traffic-jsx" */),
  "component---src-views-partners-apply-jsx": () => import("./../src/views/partners/apply.jsx" /* webpackChunkName: "component---src-views-partners-apply-jsx" */),
  "component---src-views-solutions-multi-transfer-jsx": () => import("./../src/views/solutions/multi-transfer.jsx" /* webpackChunkName: "component---src-views-solutions-multi-transfer-jsx" */),
  "component---src-views-solutions-large-file-transfer-jsx": () => import("./../src/views/solutions/large-file-transfer.jsx" /* webpackChunkName: "component---src-views-solutions-large-file-transfer-jsx" */),
  "component---src-views-solutions-data-collaboration-jsx": () => import("./../src/views/solutions/data-collaboration.jsx" /* webpackChunkName: "component---src-views-solutions-data-collaboration-jsx" */),
  "component---src-views-solutions-file-management-jsx": () => import("./../src/views/solutions/file-management.jsx" /* webpackChunkName: "component---src-views-solutions-file-management-jsx" */),
  "component---src-views-solutions-data-sync-jsx": () => import("./../src/views/solutions/data-sync.jsx" /* webpackChunkName: "component---src-views-solutions-data-sync-jsx" */),
  "component---src-views-solutions-data-migration-jsx": () => import("./../src/views/solutions/data-migration.jsx" /* webpackChunkName: "component---src-views-solutions-data-migration-jsx" */),
  "component---src-views-solutions-data-distribution-jsx": () => import("./../src/views/solutions/data-distribution.jsx" /* webpackChunkName: "component---src-views-solutions-data-distribution-jsx" */),
  "component---src-views-industry-moive-jsx": () => import("./../src/views/industry/moive.jsx" /* webpackChunkName: "component---src-views-industry-moive-jsx" */),
  "component---src-views-industry-car-jsx": () => import("./../src/views/industry/car.jsx" /* webpackChunkName: "component---src-views-industry-car-jsx" */),
  "component---src-views-industry-internet-jsx": () => import("./../src/views/industry/internet.jsx" /* webpackChunkName: "component---src-views-industry-internet-jsx" */),
  "component---src-views-industry-gene-jsx": () => import("./../src/views/industry/gene.jsx" /* webpackChunkName: "component---src-views-industry-gene-jsx" */),
  "component---src-views-industry-ad-jsx": () => import("./../src/views/industry/ad.jsx" /* webpackChunkName: "component---src-views-industry-ad-jsx" */),
  "component---src-views-industry-government-jsx": () => import("./../src/views/industry/government.jsx" /* webpackChunkName: "component---src-views-industry-government-jsx" */),
  "component---src-views-industry-financing-jsx": () => import("./../src/views/industry/financing.jsx" /* webpackChunkName: "component---src-views-industry-financing-jsx" */),
  "component---src-views-industry-manufacturing-jsx": () => import("./../src/views/industry/manufacturing.jsx" /* webpackChunkName: "component---src-views-industry-manufacturing-jsx" */),
  "component---src-views-solutions-data-transfer-jsx": () => import("./../src/views/solutions/data-transfer.jsx" /* webpackChunkName: "component---src-views-solutions-data-transfer-jsx" */),
  "component---src-views-network-ipv-6-jsx": () => import("./../src/views/network/ipv6.jsx" /* webpackChunkName: "component---src-views-network-ipv-6-jsx" */),
  "component---src-views-network-sd-wan-jsx": () => import("./../src/views/network/sd-wan.jsx" /* webpackChunkName: "component---src-views-network-sd-wan-jsx" */),
  "component---src-views-network-point-jsx": () => import("./../src/views/network/point.jsx" /* webpackChunkName: "component---src-views-network-point-jsx" */),
  "component---src-views-network-one-kilometer-jsx": () => import("./../src/views/network/one-kilometer.jsx" /* webpackChunkName: "component---src-views-network-one-kilometer-jsx" */),
  "component---src-views-network-enterprise-interconnection-jsx": () => import("./../src/views/network/enterprise-interconnection.jsx" /* webpackChunkName: "component---src-views-network-enterprise-interconnection-jsx" */),
  "component---src-views-network-hybrid-cloud-jsx": () => import("./../src/views/network/hybrid-cloud.jsx" /* webpackChunkName: "component---src-views-network-hybrid-cloud-jsx" */)
}

